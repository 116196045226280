<template>
    <div class="navmb">
		<div class="flex flexa w100 padr20">
            <el-icon :size="20" color="#FF6A00" class="icon">
                <Back class="astyle bgshadow" @click="$router.back()" />
            </el-icon>
			<span class="marl20 flex1">通知消息</span>
            <el-button text @click="setMsg">
                <el-icon color="#979797"><Setting /></el-icon>
                <span>消息接收设置 </span>
            </el-button>
		</div>
	</div>
    <el-dialog :close-on-click-modal="false"
        v-model="msg.show"
        align-center
        center
        draggable
        :close-on-press-escape="false"
        title="消息接收设置"
        width="600px"
    >
        <p class="font12 col6 marb20">建议一些工单类消息您务必设置接收，防止消息遗漏造成损失。</p>
        <el-row class="tc h40 lh40 bgf1">
            <el-col :span="msg.col">
                <span>消息类型</span>
            </el-col>
            <el-col :span="msg.col">
                <span>站内信</span>
            </el-col>
            <el-col :span="msg.col">
                <span>邮箱</span>
            </el-col>
        </el-row>
        <el-checkbox-group v-model="msg.orderMessage">
            <el-row class="tc h40 lh40">
                <el-col :span="msg.col">
                    <span class="font14">工单消息</span>
                </el-col>
                <el-col :span="msg.col">
                    <el-checkbox value="1" />
                </el-col>
                <el-col :span="msg.col">
                    <el-checkbox value="2" />
                </el-col>
            </el-row>
        </el-checkbox-group>
        <el-checkbox-group v-model="msg.systemMessage">
            <el-row class="tc h40 lh40">
                <el-col :span="msg.col">
                    <span class="font14">系统消息</span>
                </el-col>
                <el-col :span="msg.col">
                    <el-checkbox value="1" />
                </el-col>
                <el-col :span="msg.col">
                    <el-checkbox value="2" />
                </el-col>
            </el-row>
        </el-checkbox-group>
        <el-checkbox-group v-model="msg.authMessage">
            <el-row class="tc h40 lh40">
                <el-col :span="msg.col">
                    <span class="font14">验证消息</span>
                </el-col>
                <el-col :span="msg.col">
                    <el-checkbox value="1" />
                </el-col>
                <el-col :span="msg.col">
                    <el-checkbox value="2" />
                </el-col>
            </el-row>
        </el-checkbox-group>
        <template #footer>
            <el-button :loading="msg.loading" type="primary" @click="editMessage">确定</el-button>
            <el-button @click="msg.show = false">取消</el-button>
        </template>
    </el-dialog>
    <p class="h20"></p>
    <!-- <el-button-group> -->
    <div class="topbuts">
        <el-button @click="cgeType('')" :type="param.type == '' ? 'primary' : 'default'"
            plain>全部</el-button>
        <el-button @click="cgeType('1')" :type="param.type == '1' ? 'primary' : 'default'"
            plain>工单消息</el-button>
        <el-button @click="cgeType('2')" :type="param.type == '2' ? 'primary' : 'default'"
            plain>系统消息</el-button>
        <el-button @click="cgeType('3')" :type="param.type == '3' ? 'primary' : 'default'"
            plain>验证消息</el-button>
        <!-- <el-button @click="cgeType('4')" size="small" :type="param.type == '4' ? 'primary' : 'default'"
            plain>csi消息</el-button> -->
    </div>
    <!-- </el-button-group> -->
    <div class="bgf mart10" v-loading="loading">
        <el-table :data="info.records" style="width: 100%">
            <el-table-column fixed prop="date" label="消息内容" class-name="padl20 padr20" show-overflow-tooltip
                min-width="234px">
                <template #default="scope">
                    <div class="flex padr14 flexa">
                        <el-link :href="'#/newsinfo/' + scope.row.id + '/' + scope.row.type" style="display:unset"
                            class="text1">{{ scope.row.title }}</el-link>
                        <span v-if="scope.row.isRead == 0" class="diannew"></span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" show-overflow-tooltip label="接收时间">
            </el-table-column>

            <el-table-column prop="people" label="消息类型" show-overflow-tooltip min-width="80px">
                <template #default="scope">
                    <span>{{ ['', '工单消息', '系统消息','验证消息','csi消息'][scope.row.type] }} </span>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="100">
                <template #default="scope">
                    <span @click="del(scope.row.id)" class="point colred font12">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="h70 pad20 flexab">
            <p class="font10 cola4">共 {{ info.total }} 条</p>
            <el-pagination v-model:currentPage="param.pageNo" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40]" :page-size="param.pageSize"
                layout=" sizes, prev , jumper , next" :total="info.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { accountMsgRecord, removeMsg, accountBaseInfo } from "@/utils/api1.js"
import { mapState, mapGetters } from 'vuex';
import { Back, Setting } from '@element-plus/icons-vue'
export default {
    components: {
        Back, Setting
    },
    computed: {
        ...mapState({
            userInfo: state => state.userinfo,
        }),
    },
    data() {
        return {
            loading: false,
            param: {
                type: '',
                pageNo: 1,
                pageSize: 10,
            },
            info: {records:[]},
            msg: {
                show: false,
                orderMessage: [],
                systemMessage: [],
                authMessage: [],
                loading: false,
                col: 8,
            },
        }
    },
    mounted() {
        this.getList();
        this.accountBaseInfo()
    },
    methods: {
        setMsg() {
            Object.assign(this.msg, {
                show: true,
                orderMessage: this.userInfo.orderMessage ? this.userInfo.orderMessage.split(',') : [],
                systemMessage: this.userInfo.systemMessage ? this.userInfo.systemMessage.split(',') : [],
                authMessage: this.userInfo.authMessage ? this.userInfo.authMessage.split(',') : [],
            })
            console.log('this.msg.orderMessage',this.msg.orderMessage)
            // if (!this.msg.orderMessage.every(item => ['1', '2'].includes(item))) {
            //     this.msg.orderMessage = []
            // }
            // if (!this.msg.systemMessage.every(item => ['1', '2'].includes(item))) {
            //     this.msg.systemMessage = []
            // }
            // if (!this.msg.authMessage.every(item => ['1', '2'].includes(item))) {
            //     this.msg.authMessage = []
            // }
        },
        async editMessage() {
            try {
                this.msg.loading = true
                const res = await this.$api.user.editMessage({
                    authMessage: this.msg.authMessage.join(','),
                    orderMessage: this.msg.orderMessage.join(','),
                    systemMessage: this.msg.systemMessage.join(','),
                })
                if (res) {
                    this.$message.success(res.message)
                    this.msg.show = false
                    this.accountBaseInfo()
                }
            } catch (e) {
                return console.log(e)
            } finally {
                this.msg.loading = false
            }
        },
        async accountBaseInfo() {
            try {
                this.$store.commit('setUserinfo', JSON.stringify(await accountBaseInfo()))
                let btn = await accountBaseInfo()
                console.log('btn',btn)
            } catch (e) {
                return console.log(e)
            }
        },
        getList() {
            this.loading = true;
            console.log(this.param)
            accountMsgRecord(this.param).then(res => {
                console.log(res);
                if (res) {
                    this.info = res
                }
                this.loading = false;
            })
        },

        cgeType(num) {
            this.param.type = num;
            this.param.pageNo = 1;
            this.getList();
        },
        del(id) {
            this.$confirm('删除消息后您将无法在消息列表中查看到该消息了', '您确定要从消息列表删除掉这个消息嘛？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                removeMsg({id}).then(res => {
                    console.log(res);
                    if (res) {
                        this.$message.success(res.message);
                        this.getList();
                    }
                })
            }).catch(() => { });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.param.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.param.pageNo = val;
            this.getList();
        },
    }
}
</script>
<style lang="scss" scoped>
.w300 {
    width: 300px;
}

.topbuts {
    button {
        margin: 0;
        --el-button-bg-color: #fff;
        
    }
    .el-button--primary.is-plain {
        --el-button-border-color: #FF6A00;
        --el-button-hover-bg-color: #fff;
        --el-button-hover-text-color: #FF6A00;
    }
}

.diannew {
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 80%;
    margin-bottom: 1px;
    display: inline-block;
    background: #EE1637;
    margin-left: 6px;
}
</style>